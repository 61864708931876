.paymentDropdown {
  position: relative;
  display: inline-flex;
  font-size: var(--sFontSmall);
}

.paymentDropdown__triggerLabelWrapper {
  display: inline-grid;
  grid-template-areas: 'label';
}

.paymentDropdown__trigger {
  height: 45px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--cBackground);
  color: var(--cText);
  border: none;
  appearance: none;
  padding: 0 15px;
  font-family: var(--fontAccent);
  font-size: inherit;
  line-height: 1;
  cursor: pointer;
  outline: none;
  margin: 0;
}

.paymentDropdown__trigger > *:not(:last-child) {
  margin-right: 10px;
}

.paymentDropdown__trigger:global(.focus-visible) {
  box-shadow: var(--focusRingSecondary);
}

@media (max-width: 1024px) {
  .paymentDropdown__trigger {
    height: 45px;
    padding: 0 10px;
  }
}

.paymentDropdown__trigger svg {
  transition-duration: var(--tFast);
  transition-timing-function: var(--tfStandart);
  flex-shrink: 0;
}

.paymentDropdown_open .paymentDropdown__trigger svg:last-child {
  transform: scaleY(-1);
}

.paymentDropdown_open .paymentDropdown__trigger:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.paymentDropdown__triggerLabel {
  position: relative;
  grid-area: label;
  text-align: left;
}

.paymentDropdown__triggerLabel_hidden {
  visibility: hidden;
}

.paymentDropdown__dropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  display: none;
  background: var(--cBackground);
  z-index: 1;
  padding-bottom: 10px;
}

.paymentDropdown_open .paymentDropdown__dropdown {
  display: flex;
  flex-direction: column;
}

.paymentDropdown__dropdownButton {
  font: inherit;
  text-align: left;
  padding: 6px 15px;
  min-height: 42px;
  background: none;
  border: none;
  color: var(--cText);
  position: relative;
  cursor: pointer;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  display: flex;
  align-items: center;
  margin: 0;
}

.paymentDropdown__dropdownButton > svg {
  margin-right: 10px;
}

.paymentDropdown__dropdownButton svg {
  flex-shrink: 0;
}

@media (max-width: 1024px) {
  .paymentDropdown__dropdownButton {
    padding: 6px 10px;
  }
}

.paymentDropdown__dropdownButton:hover {
  /*background-color: rgba(0, 0, 0, 0.05);*/
  color: var(--cTheme);
}
