@font-face {
  font-family: 'Alfabet';
  src: url('../fonts/Alfabet-Light.woff2') format('woff2'),
    url('../fonts/Alfabet-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Alfabet';
  src: url('../fonts/Alfabet-Regular.woff2') format('woff2'),
    url('../fonts/Alfabet-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

a {
  color: var(--cText);
  outline: none;
}

a:hover,
a.focus-visible {
  text-decoration: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--fontAccent);
  color: var(--cText);
  letter-spacing: 0.01em;
}

.h1 {
  margin-top: 0;
  margin-bottom: 0.5em;
  padding-bottom: 0;
  letter-spacing: 0.01em;
  line-height: 1.1;
  font-weight: 300;
  font-size: var(--sFontH1);
  color: var(--cText);
  text-transform: uppercase;
}

.h1_noMargin {
  margin: 0;
}

.h2 {
  margin-top: 0;
  margin-bottom: 0.5em;
  padding-bottom: 0;
  font-weight: 400;
  font-size: var(--sFontH2);
}

.h2_noMargin {
  margin: 0;
}

.h3 {
  margin-top: 0;
  margin-bottom: 0.5em;
  padding-bottom: 0;
  font-size: var(--sFontH3);
  font-weight: 400;
}

.h3_noMargin {
  margin: 0;
}

.h4 {
  margin-top: 0;
  margin-bottom: 0.5em;
  padding-bottom: 0;
  font-size: var(--sFontH4);
  font-weight: 500;
}

.h4_noMargin {
  margin: 0;
}

.text {
  line-height: 1.7;
}

.text li {
  margin-bottom: 0.3em;
}

.text h1 {
  font-size: calc(30rem / 16);
  font-weight: 700;
  font-family: var(--fontAccent);
}

.text h2 {
  font-size: calc(24rem / 16);
  font-weight: 700;
  font-family: var(--fontAccent);
}

.text h3 {
  font-size: calc(30rem / 16);
  font-weight: 400;
  font-family: var(--fontAccent);
}

.text p,
.text ul {
  margin-top: 0;
  line-height: 1.5;
}
.text p:last-child {
  margin-bottom: 0;
}

.text a {
  color: var(--cTheme);
  text-decoration: none;
}

.text a:hover {
  text-decoration: underline;
}
