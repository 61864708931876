.cookiePopup {
  position: fixed;
  --offset: 60px;
  bottom: var(--offset);
  left: var(--offset);
  right: var(--offset);
  max-width: 480px;
  padding: 35px;
  background-color: var(--cBackgroundInverse);
  color: var(--cTextInverse);
  box-sizing: border-box;
  border: 1px solid var(--cTextInverse);
}

@media (max-width: 1024px) {
  .cookiePopup {
    --offset: 20px;
  }
}

.cookiePopup__content {
  margin-bottom: 26px;
  font-size: var(--sFontSmall);
}

.cookiePopup__content p {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.cookiePopup__footer {
  --cText: var(--cTextInverse);
  --cShadeLight: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.cookiePopup__footer > *:not(:last-child) {
  margin-right: 15px;
}

.cookiePopup__footerWing {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.cookiePopup__footerWing > *:not(:last-child) {
  margin-right: 15px;
}
