.titleDropdown {
  display: inline-flex;
  position: relative;
}

.titleDropdown__trigger {
  display: inline-flex;
  align-items: center;
  font: inherit;
  font-weight: bold;
  padding: 0;
  border: none;
  background: none;
  appearance: none;
  cursor: pointer;
  margin: 0;
  line-height: inherit;
  letter-spacing: inherit;
  position: relative;
  color: var(--cTheme);
}

.titleDropdown__trigger:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 10px 0 10px;
  border-color: currentColor transparent transparent transparent;
  margin-left: 15px;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
}

.titleDropdown_open .titleDropdown__trigger:after {
  transform: scaleY(-1);
}

.titleDropdown__triggerLabel {
  position: relative;
}

.titleDropdown__triggerLabel:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 5px;
  right: 5px;
  height: 5px;
  margin-top: 2px;
  background: var(--cTheme);
}

.titleDropdown_open .titleDropdown__triggerLabel:after {
  display: none;
}

.titleDropdown__dropdown {
  position: absolute;
  top: 100%;
  display: none;
  background: var(--cBackgroundInverse);
  border: 4px solid var(--cTheme);
  margin-top: 2px;
}

.titleDropdown_open .titleDropdown__dropdown {
  display: flex;
  flex-direction: column;
}

.titleDropdown__dropdownButton {
  font: inherit;
  font-size: 0.65em;
  padding: 25px 30px;
  background: none;
  border: none;
  color: var(--cTextInverse);
  position: relative;
  cursor: pointer;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  margin: 0;
}

.titleDropdown__dropdownButton:hover {
  background: var(--cBackgroundInverse);
  background-color: rgba(255, 255, 255, 0.1);
}

.titleDropdown__dropdownButton:not(:first-child):before {
  display: block;
  content: '';
  height: 1px;
  position: absolute;
  bottom: 100%;
  left: 20px;
  right: 20px;
  background-color: #757e86;
}
