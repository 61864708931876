.main {
  margin-bottom: 180px;
  flex-grow: 1;
}

@media (max-width: 1024px) {
  .main {
    margin-bottom: 120px;
  }
}

@media (max-width: 768px) {
  .main {
    margin-bottom: 80px;
  }
}
