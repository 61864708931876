.footerLogos {
  padding-block: 25px;
  border-top: 1px solid var(--cBorderInverse);
  border-bottom: 1px solid var(--cBorderInverse);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -5px -10px;
}

.footerLogos > * {
  margin: 5px 10px;
}

@media (max-width: 768px) {
  .footerLogos {
    justify-content: center;
  }
}
