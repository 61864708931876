.questionsSection {
  display: grid;
  grid-template-columns: minmax(30%, 400px) auto;
  gap: 80px;
}

@media (max-width: 1024px) {
  .questionsSection {
    gap: 40px;
  }
}

@media (max-width: 1024px) {
  .questionsSection {
    grid-template-columns: 1fr;
  }
}

.questionsSection__nav {
  display: grid;
  align-self: start;
  gap: 30px;
}

@media (max-width: 1024px) {
  .questionsSection__nav {
    display: flex;
    gap: 0;
    --gap: 20px;
    margin: calc(var(--gap) / -2);
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .questionsSection__nav {
    --gap: 8px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

.questionsSection__navLink {
  display: block;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 50px;
  padding-right: 30px;
  border: 1px solid var(--cBorderLight);
  text-decoration: none;
  font-size: var(--sFontH4);
  font-family: var(--fontAccent);
}

@media (max-width: 1024px) {
  .questionsSection__navLink {
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: calc(15rem / 16);
    margin: calc(var(--gap) / 2);
  }
}

.questionsSection__navLink:not(.questionsSection__navLink_active):hover {
  background-color: var(--cShadeLight);
}

@media (max-width: 768px) {
  .questionsSection__navLink {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: var(--sFontSmall);
    background-color: #ecedf2;
    border: none;
  }

  .questionsSection__navLink:not(.questionsSection__navLink_active):hover {
    background-color: #ecedf2;
    color: var(--cTheme);
  }
}

.questionsSection__navLink_active {
  background-color: var(--cTheme);
  border-color: var(--cTheme);
  color: var(--cTextInverse);
}

.questionsSection__navLink:global(.focus-visible) {
  box-shadow: var(--focusRingSecondary);
}

.questionsSection__details {
  padding-bottom: 50px;
}

@media (max-width: 1024px) {
  .questionsSection__details {
    padding-bottom: 30px;
  }
}

.questionsSection__details:not(:last-child) {
  border-bottom: 1px solid var(--cBorderLight);
  margin-bottom: 50px;
}

@media (max-width: 1024px) {
  .questionsSection__details:not(:last-child) {
    margin-bottom: 30px;
  }
}

.questionsSection__detailsSummary {
  display: flex;
  font-size: var(--sFontH3);
  cursor: pointer;
  align-items: center;
}

.questionsSection__detailsSummary::marker {
  display: none;
}

.questionsSection__detailsSummary {
  list-style: none;
}

.questionsSection__detailsSummary::-webkit-details-marker {
  display: none;
}

.questionsSection__detailsIcon {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  /*margin-top: 3px;*/
  flex-shrink: 0;
  /*align-self: start;*/
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  margin-right: 35px;
  align-self: center;
}

.questionsSection__detailsIcon::before,
.questionsSection__detailsIcon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 24px;
  height: 2px;
  background-color: var(--cText);
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
}

.questionsSection__detailsIcon::before {
  transform: rotate(90deg);
}

.questionsSection__details[open] .questionsSection__detailsIcon {
  transform: rotate(90deg);
}

.questionsSection__details[open] .questionsSection__detailsIcon::after {
  transform: rotate(90deg);
}

.questionsSection__detailsContent {
  margin-top: 30px;
  padding-left: 59px;
}

@media (max-width: 768px) {
  .questionsSection__detailsContent {
    padding-left: 0;
  }
}
