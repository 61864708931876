.bigButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 35px;
  padding-inline: 35px;
  color: var(--cTextInverse);
  background-color: var(--cTheme);
  text-decoration: none;
  font-size: var(--sFontH4);
}

.bigButton > *:not(:last-child) {
  margin-right: 15px;
  display: block;
}

.bigButton_auto {
  display: inline-flex;
}

@media screen and (max-width: 1024px) {
  .bigButton {
    font-size: var(--sFontBody);
    padding-block: 24px;
  }
  .bigButton svg {
    transform: scale(0.6);
  }

  .bigButton > *:not(:last-child) {
    margin-right: 8px;
  }
}

.bigButton:hover {
  background-color: var(--cThemeActive);
}

.bigButton:active {
  background-color: var(--cTheme);
}

.bigButton:global(.focus-visible) {
  box-shadow: var(--focusRingSecondary);
}
