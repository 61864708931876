.textGrid {
  display: grid;
  grid-template-columns: minmax(690px, 67%) auto;
  gap: 40px;
}

@media (max-width: 1024px) {
  .textGrid {
    grid-template-columns: minmax(475px, 67%) auto;
  }
}

@media (max-width: 768px) {
  .textGrid {
    grid-template-columns: 1fr;
  }
}

.textGrid__body {
  position: relative;
  min-width: 0;
}

.textGrid__aside {
  position: sticky;
  align-self: flex-start;
  top: 20px;
  min-width: 0;
}

@media (max-width: 768px) {
  .textGrid__aside {
    position: static;
    grid-row: 1;
  }
}
