.contactsForm {
}

.contactsForm__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
  margin-right: -80px;
}

.contactsForm__header > * {
  margin-right: 80px;
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .contactsForm__header {
    margin-bottom: 20px;
  }
}

.contactsForm__link {
  display: flex;
  align-items: center;
  outline: none;
  text-decoration: none;
}

.contactsForm__link:global(.focus-visible) .contactsForm__icon {
  box-shadow: var(--focusRingSecondary);
}

.contactsForm__link:hover .contactsForm__icon {
  background-color: var(--cTheme);
}

.contactsForm__icon {
  height: 50px;
  width: 50px;
  background-color: var(--cText);
  color: var(--cTextInverse);
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: var(--tFast);
  transition-timing-function: var(--tfStandart);
  flex-shrink: 0;
  margin-right: 20px;
}

.contactsForm__icon svg {
  display: block;
}

.contactsForm__grid {
  display: grid;
  grid-template-columns: minmax(67%, 900px) auto;
  gap: 46px;
  align-items: end;
}

@media (max-width: 768px) {
  .contactsForm__grid {
    grid-template-columns: 1fr;
    gap: 25px;
  }
}

.contactsForm__success {
  font-size: var(--sFontH3);
}

.contactsForm__formColumn {
  display: flex;
  flex-direction: column;
}

.contactsForm__formColumn > *:not(:last-child) {
  margin-bottom: 25px;
}

.contactsForm__action {
  width: 150px;
  height: 150px;
  background-color: var(--cTheme);
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: var(--tFast);
  transition-timing-function: var(--tfStandart);
  flex-shrink: 0;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--cTextInverse);
  font: inherit;
}

.contactsForm__action:disabled {
  cursor: not-allowed;
  background-color: var(--cBorderInverse);
}

.contactsForm__action svg {
  display: block;
}

@media (max-width: 1024px) {
  .contactsForm__action {
    margin-left: auto;
    height: 100px;
    width: 100px;
  }
}

@media (min-width: 769px) {
  .contactsForm__actionLabel {
    display: none;
  }

  .contactsForm__action svg:last-of-type {
    display: none;
  }
}

@media (max-width: 768px) {
  .contactsForm__action {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    min-height: 72px;
    padding-inline: 26px;
  }

  .contactsForm__action svg {
    display: none;
  }

  .contactsForm__action svg:last-of-type {
    display: block;
  }

  .contactsForm__actionLabel {
  }
}

.contactsForm__action:not(:disabled):global(.focus-visible) {
  box-shadow: var(--focusRingSecondary);
}

.contactsForm__action:not(:disabled):hover {
  background-color: transparent;
  color: var(--cTheme);
}

.contactsForm__action:not(:disabled):active {
  background-color: transparent;
  color: var(--cTheme);
}

@media (max-width: 768px) {
  .contactsForm__action:not(:disabled):hover {
    background-color: var(--cThemeActive);
    color: var(--cTextInverse);
  }

  .contactsForm__action:not(:disabled):active {
    background-color: var(--cTheme);
    color: var(--cTextInverse);
  }
}
