.footerMenu {
  color: var(--cTextInverse);
}

.footerMenu__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-block: 0;
  list-style: none;
  padding: 0;
  margin-top: 23px;
}

.footerMenu__title {
  margin-bottom: 20px;
}
