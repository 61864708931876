html,
body {
  margin: 0;
  min-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}
