.footerMenuLink {
  display: block;
  color: var(--cText);
  text-decoration: none;
  font-size: var(--sFontBig);
  padding-block: 8px;
}

.footerMenuLink:hover,
.footerMenuLink:focus-visible {
  text-decoration: underline;
}
