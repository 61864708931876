.homeBlock {
  display: grid;
  grid-template-columns: auto max(40%, 500px);
  gap: 60px;
  margin-bottom: 60px;
}

@media (max-width: 1024px) {
  .homeBlock {
    grid-template-columns: auto max(40%, 330px);
  }
}

@media (max-width: 768px) {
  .homeBlock {
    display: block;
  }
}

.homeBlock__contentWrapper {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

@media (max-width: 768px) {
  .homeBlock__contentWrapper {
    margin-bottom: 45px;
  }
}

.homeBlock__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.homeBlock__text {
  font-size: var(--sFontExtraBig);
  margin-top: 30px;
  max-width: 360px;
}

.homeBlock__footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-block: 24px;
}

@media (max-width: 1024px) {
  .homeBlock__actionWrapper {
    display: none;
  }

  .homeBlock__footer {
    order: -1;
    display: block;
  }

  .homeBlock__content {
    flex-grow: 0;
  }

  .homeBlock__contentWrapper {
    gap: 30px;
    display: grid;
    align-items: start;
    align-self: flex-start;
  }

  .homeBlock__text {
    font-size: calc(13rem / 16);
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .homeBlock__text {
    font-size: calc(15rem / 16);
  }
}

.homeBlock__trustpilotRating {
  font-size: 2.25rem;
  font-weight: 500;
  margin-bottom: 6px;
}

@media (max-width: 1024px) {
  .homeBlock__trustpilotRow > *:not(:last-child) {
    margin-right: 16px;
  }

  .homeBlock__trustpilot {
    align-items: center;
  }

  .homeBlock__trustpilotRating {
    margin-bottom: 0;
  }
}

.homeBlock__trustpilotRating strong {
  color: var(--cTheme);
  font-weight: 500;
}

.homeBlock__trustpilotLabel {
  color: #616161;
  font-size: var(--sFontTiny);
}

.homeBlock__trustpilotLabel strong {
  color: var(--cText);
  font-weight: inherit;
}

.homeBlock__action {
  display: flex;
  align-items: center;
  font: inherit;
  font-size: var(--sFontExtraBig);
  border: none;
  background: none;
  appearance: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 136px;
  padding-left: 30px;
  line-height: 2;
  position: relative;
  outline: none;
  text-decoration: none;
  /*stroke-dashoffset: 330;*/
  stroke-dashoffset: 410;
}

.homeBlock__actionIcon {
  height: 16px;
  width: 25px;
  position: relative;
  transition-duration: var(--tFast);
  transition-timing-function: var(--tfStandart);
  margin-left: 10px;
}

.homeBlock__actionIcon svg {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
}

.homeBlock__actionIcon svg:first-child {
  transform-origin: right center;
  transform: translateY(-50%);
}

.homeBlock__circle {
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
}

.homeBlock__circle circle {
  transition-duration: calc(var(--tGeneric) * 2);
  transition-timing-function: var(--tfStandart);
}

.homeBlock__action:hover .homeBlock__circle circle,
.homeBlock__action:global(.focus-visible) .homeBlock__circle circle {
  stroke-dashoffset: 0;
}

.homeBlock__action:hover .homeBlock__actionIcon,
.homeBlock__action:global(.focus-visible) .homeBlock__actionIcon {
  margin-left: 15px;
  transition-delay: var(--tFast);
}
.homeBlock__action:hover .homeBlock__actionIcon svg:first-child,
.homeBlock__action:global(.focus-visible)
  .homeBlock__actionIcon
  svg:first-child {
  transform: translateY(-50%) scaleX(125%);
  transition-delay: var(--tFast);
}

.homeBlock__action:global(.focus-visible):before {
  box-shadow: var(--focusRingSecondary);
}

.homeBlock__action span {
  position: relative;
  background-color: var(--cBackground);
}

.homeBlock__actions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-inline: -5px;
  margin-bottom: -5px;
}

.homeBlock__actions > * {
  margin-inline: 5px !important;
  margin-bottom: 5px !important;
}

@media (min-width: 1025px) {
  .homeBlock__actions {
    display: none;
  }
}
