.errorPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.errorPage__logo {
  margin-top: 30px;
  display: block;
}
