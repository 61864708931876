html,
body {
  height: 100%;
  margin: 0;
  color: var(--cText);
  letter-spacing: 0.01em;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#root > * {
  flex-grow: 1;
}
