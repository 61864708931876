

.affiliate__title {
  margin-bottom: 40px !important;
}

.affiliate__action {
  margin-top: 80px;
}

@media (max-width: 768px) {
  .affiliate__action {
    margin-top: 40px;
  }
}