.section {
  padding-block: 150px;
}

.section_inverted {
  background-color: var(--cBackgroundInverse);
  --cText: var(--cTextInverse);
  color: var(--cText);
}

.section_shade {
  background-color: #eff1f4;
}

.section_inverted > * {
  color: var(--cText);
}

.section__title {
  text-align: center;
  margin: 0;
  padding-bottom: 20px !important;
}

.section__description {
  text-align: center;
  max-width: 420px;
  margin: auto;
  font-size: var(--sFontBig);
}

@media (max-width: 1024px) {
  .section__description {
    font-size: var(--sFontTiny);
  }
}

@media (max-width: 768px) {
  .section__description {
    font-size: var(--sFontBody);
  }
}

.section_shade .section__description {
  color: #1e1f22;
  font-weight: 400;
}

.section_inverted .section__description {
  color: var(--cTextInverseLight);
}

.section__titleContainer {
  margin-bottom: 80px;
  font-size: var(--sFontBig);
}

@media screen and (max-width: 1024px) {
  .section__titleContainer {
    margin-bottom: 60px;
  }

  .section {
    padding-block: 80px;
  }
}

@media screen and (max-width: 768px) {
  .section__titleContainer {
    margin-bottom: 40px;
  }

  .section {
    padding-block: 60px;
  }
}
