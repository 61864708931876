.errors {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  padding-inline: 10px;
  margin-bottom: 10px;
}

.errors__error {
  margin: 0;
  font-size: var(--sFontExtraBig);
  color: var(--cError);
}
