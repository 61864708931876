.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 33px;
  box-sizing: border-box;
  margin: auto;
  align-items: stretch;
}

.cardGrid_narrow {
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
}

.cardGrid__item {
  align-self: stretch;
}

@media (max-width: 1024px) {
  .cardGrid {
    grid-gap: 20px;
  }
}

@media (min-width: 769px) {
  .cardGrid {
    width: 100%;
    max-width: calc((20px * 2) + var(--sContainerWidth));
    padding-inline: 20px;
  }
}

@media (max-width: 768px) {
  .cardGrid {
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;
    padding-bottom: 10px;
    gap: 0;
  }

  .cardGrid__item {
    flex-basis: 0;
    flex-grow: 1;
    scroll-snap-align: start;
    padding-left: 20px;
    min-width: 300px;
  }

  .cardGrid__item:last-child {
    padding-right: 20px;
  }

  .cardGrid_narrow .cardGrid__item {
    min-width: 260px;
  }
}

@media (max-width: 360px) {
  .cardGrid__item {
    flex-basis: 0;
    flex-grow: 1;
    scroll-snap-align: start;
    padding-left: 20px;
    min-width: 260px;
  }
}
