.priceTable {
}

.priceTable__table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: var(--sFontBig);
  margin-bottom: 60px;
}

.priceTable__table th {
  color: var(--cTextInverseLightSecondary);
  font-weight: normal;
  text-align: left;
  padding-bottom: 20px;
}

.priceTable__footnote {
  font-size: var(--sFontSmall);
  text-align: right;
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .priceTable__table {
    font-size: var(--sFontSmall);
  }
}

@media screen and (min-width: 769px) {
  .priceTable__table td:not(:last-child),
  .priceTable__table th:not(:last-child) {
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .priceTable,
  .priceTable__table {
    display: grid;
  }

  .priceTable__table {
    font-size: var(--sFontBody);
  }

  .priceTable__tableHead {
    display: none;
  }
}
