.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav__container {
  padding-block: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1200px) {
  .nav__container {
    padding-block: 25px;
  }
}

.nav__wing,
.nav__desktopMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

@media (max-width: 1200px) {
  .nav__desktopMenu {
    display: none;
  }
  .nav__wing {
    gap: 0;
  }
}

@media (min-width: 1201px) {
  .nav__mobileMenu {
    display: none;
  }
}

.nav__nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  border-right: 1px solid var(--cBorderLight);
}

.nav__modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--cBackground);
  z-index: var(--zModal);
  display: none;
  flex-direction: column;
}

.nav__modal_open {
  display: flex;
}

.nav__modalWrapper {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.nav__modalContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 30px;
}

.nav__modalFooter {
  margin-bottom: 40px;
}

.nav__modalFooterNav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav__modalFooterNav > *:not(:last-child) {
  margin-right: 15px;
}

@media (max-width: 768px) {
  .nav__modalFooterNav {
    justify-content: center;
  }
}

.nav__modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav__modalNav {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .nav__modalNav {
    align-items: center;
  }
}

.nav__modalNav li {
  padding: 0;
}

.nav__modalNav a {
  text-decoration: none;
  display: inline-flex;
  font-size: calc(52rem / 16);
  font-family: var(--fontAccent);
  padding-block: 20px;
  position: relative;
}

@media (max-width: 768px) {
  .nav__modalNav a {
    font-size: calc(33rem / 16);
  }
}

.nav__modalNav a:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--cText);
  margin-top: -15px;
  opacity: 0;
  transition-duration: var(--tFast);
  transition-timing-function: var(--tfStandart);
}

.nav__modalNav a:hover:after {
  opacity: 1;
}

.nav__logo img {
  display: block;
}

@media (max-width: 768px) {
  .nav__logo img {
    width: 140px;
  }
}
