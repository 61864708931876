.stepsBlock {
  display: grid;
  gap: 30px 45px;
  grid-template-areas: 'header content' 'image content';
}

.stepsBlock__contentItem:before {
  align-items: center;
  border-radius: 50%;
  color: var(--cTextInverse);
  content: counter(step);
  display: flex;
  flex-shrink: 0;
  font-family: var(--fontSecondary);
  font-size: var(--sFontH4);
  font-weight: 700;
  height: 45px;
  justify-content: center;
  margin-right: 20px;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  width: 45px;
}

@media screen and (max-width: 1024px) {
  .stepsBlock {
    grid-template-columns: auto max(40%, 278px);
  }
}

@media screen and (max-width: 768px) {
  .stepsBlock {
    display: block;
  }
}

.stepsBlock__header {
  grid-area: header;
}

.stepsBlock__title {
  text-align: left;
}

@media screen and (max-width: 768px) {
  .stepsBlock__title {
    text-align: center;
  }
}

.stepsBlock__image {
  grid-area: image;
  display: flex;
  /*justify-content: center;*/
}

@media screen and (max-width: 768px) {
  .stepsBlock__image {
    display: none;
  }
}

.stepsBlock__image img {
  max-width: 100%;
}

.stepsBlock__content {
  grid-area: content;
  width: 500px;
}

@media screen and (max-width: 1024px) {
  .stepsBlock__content {
    /*min-width: 287px;*/
    width: 100%;
  }
}

.stepsBlock__list {
  counter-reset: step;
  list-style: none;
  padding: 0;
}

.stepsBlock__contentItem {
  counter-increment: step;
  border: 1px solid transparent;
  padding: 30px 30px;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  outline: none;
}

@media screen and (min-width: 769px) {
  .stepsBlock__contentItem:global(.focus-visible),
  .stepsBlock__contentItem:focus-visible {
    box-shadow: var(--focusRingSecondary);
  }
  .stepsBlock__contentItem_active:before {
    content: counter(step);
    background-color: var(--cTheme);
    color: var(--cNumberStep);
    box-shadow: 0 0 36px var(--cTheme);
  }
}

@media screen and (max-width: 1024px) {
  .stepsBlock__contentItem {
    font-size: var(--sFontTiny);
    padding: 20px;
  }
  .stepsBlock__contentItem:before {
    margin-right: 20px;
  }
}

.stepsBlock__contentItemWrapper {
  display: flex;
  flex-direction: column;
}

.stepsBlock__contentItemWrapper > *:not(:last-child) {
  margin-bottom: 24px;
}

@media screen and (max-width: 768px) {
  .stepsBlock__image {
    display: none;
  }

  .stepsBlock__contentItem {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: var(--sFontSmall);
    cursor: auto;
  }

  .stepsBlock__contentItem > * {
    margin-top: 15px;
  }

  .stepsBlock__contentItem:before {
    font-size: var(--sFontH3);
    margin-right: 0;
    height: 30px;
    width: 30px;
    background-color: var(--cTheme);
    color: var(--cTextInverse);
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 28px;
    padding: 0;
    border-radius: 50%;
    color: var(--cNumberStep);
    box-shadow: 0 0 36px var(--cTheme);
  }

  .stepsBlock__contentItemTitle {
    font-size: var(--sFontH4);
  }
}

.stepsBlock__contentItemImage {
  margin-top: 20px;
  margin-bottom: 40px;
}

.stepsBlock__contentItemImage img {
  width: 360px;
  max-width: 100%;
}

@media screen and (min-width: 769px) {
  .stepsBlock__contentItemImage {
    display: none;
  }
}
