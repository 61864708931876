.footer {
  background-color: var(--cBackgroundInverse);
  padding-block: 60px;
  --cText: var(--cTextInverse);
  color: var(--cText);
}

.footer__menuRow {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px 20px;
  padding-bottom: 60px;
}

.footer__copyright {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  font-size: calc(13rem / 16);
  padding-top: 60px;
  color: #ecedf2;
  line-height: 1.69;
}

.footer__copyright p {
  margin: 0;
}

.footer__copyright > *:last-child {
  text-align: right;
}

@media (max-width: 768px) {
  .footer__copyright {
    grid-template-columns: 1fr;
  }

  .footer__copyright > *:last-child {
    text-align: left;
  }
}
